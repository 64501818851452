
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { ChartData, ChartOptions } from 'chart.js';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import FleetGraphTooltip from '@/modules/cars/modules/fleet/components/graph/fleet-graph-tooltip.vue';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type UserService from '@/modules/user/user.service';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import type Day from '@/modules/common/types/day.type';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';

@Component({
    components: {
        CustomGraph,
        LoaderWrapper,
        FleetGraphTooltip,
    },
})
export default class FleetGraphRange extends Vue {
    @inject(FleetServiceS) private fleetService!: FleetService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.UserService) private userService!: UserService;

    @Prop({
        type: String,
        required: true,
    })
    provider!: string;

    onTooltipClick(day: Day) {
        this.$router.push({
            name: 'fleet-density.graph.day-popup-source',
            params: {
                day: String(day),
                source: String(this.provider),
            },
        });
    }

    get chartData(): ChartData {
        if (!this.documentFiltersService.days) {
            return {
                labels: [],
                datasets: [],
            };
        }

        const myRates: (number | null)[] = [];
        const min: (number | null)[] = [];
        const max: (number | null)[] = [];
        this.documentFiltersService.days.forEach(day => {
            min.push(this.fleetService.brokerMin(day, this.provider));
            max.push(this.fleetService.brokerMax(day, this.provider));
            myRates.push(this.fleetService.currentCompanyCount(day, this.provider));
        });

        const currentColor: string = this.userService.currentCompany ? PROVIDER_COLORS[this.userService.currentCompany] : '#555555';

        return {
            labels: this.documentFiltersService.days,
            // @ts-ignore
            datasets: [
                {
                    label: this.provider,
                    data: myRates,
                    borderColor: currentColor,
                    pointRadius: 3,
                    borderWidth: 3,
                    pointBackgroundColor: 'white',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'Min',
                    data: min,
                    borderDash: [0, 1],
                    backgroundColor: '#E9F7FD',
                    fill: '2',
                    pointRadius: 0,
                    borderColor: '#79CFF3',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'Max',
                    data: max,
                    borderDash: [0, 1],
                    borderColor: '#79CFF3',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
            ],
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            tooltips: { enabled: false },
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 0,
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                    ticks: {
                        callback(value) {
                            return value < 10 ? `0${value}` : value;
                        },
                    },
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: '# Cars',
                    },
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    offset: true,
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                        callback(value) {
                            return value >= 10000 ? `${Number(value) / 1000}k` : value;
                        },
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }
}
